/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Vid = styled.video``;

const Video = ({ src, className }) => {
  const video = useRef(null);
  useEffect(() => {
    if (video) video.current.play();
  }, [video]);
  return (
    <Vid ref={video} controls autoplay muted className={className}>
      <source src={src} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </Vid>
  );
};

export default Video;

Video.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};
Video.defaultProps = {
  className: '',
};
