import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translate } from '../../resources/translations';

const Container = styled.div`
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top:110px;
`;

const Row = styled.div`
padding:10px 10px 10px; 10px;
`;

const Input = styled.input`
  padding: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const Button = styled.button``;

const Searchbar = ({ companyName, jobType, workLocation, dir }) => {
  return (
    <Container className="container bg-primary" dir={dir}>
      <Row className="row">
        {companyName && (
          <Input className="col-sm form-control" placeholder={translate('company_name')} />
        )}
        {jobType && <Input className="col-sm form-control" placeholder={translate('job_type')} />}
        {workLocation && (
          <Input className="col-sm form-control" placeholder={translate('work_location')} />
        )}
        <Button className="col-1 btn btn-primary">{translate('search')}</Button>
      </Row>
    </Container>
  );
};

export default Searchbar;

Searchbar.propTypes = {
  companyName: PropTypes.bool,
  jobType: PropTypes.bool,
  workLocation: PropTypes.bool,
  dir: PropTypes.string,
};

Searchbar.defaultProps = {
  companyName: false,
  jobType: true,
  workLocation: true,
  dir: null,
};
