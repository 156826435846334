/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect } from 'react';
import { Dropdown, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LanguageContext } from '../../contexts';
import { translate } from '../../resources/translations';
// import styles from '../../styles/styles.scss';
import { isMobile } from 'react-device-detect';


const Header = ({ location, forwardedRef }) => {
  const { language, setLanguage, active, setActive } = useContext(LanguageContext);

  useEffect(() => {
    setActive(location.pathname);
  }, [location, setActive]);

  const renderDrawer = () => (
    <Navbar.Collapse variant="light" id="basic-navbar-nav">
      <Nav className="mr-auto text-dark p-3">
        <Nav.Link className="text-dark">
          <Link to="/">
            {translate('home')} {active === '/' && <span className="sr-only">(current)</span>}
          </Link>
        </Nav.Link>
        <Nav.Link className="text-dark">
          <Link to="/jobs">
            {translate('jobs')} {active === '/jobs' && <span className="sr-only">(current)</span>}
          </Link>
        </Nav.Link>
        <Nav.Link className="text-dark">
          <Link to="/events">
            {translate('events')}{' '}
            {active === '/events' && <span className="sr-only">(current)</span>}
          </Link>
        </Nav.Link>

        <Nav.Link className="text-dark">
          <Link to="/media">
            {translate('media')} {active === '/media' && <span className="sr-only">(current)</span>}
          </Link>
        </Nav.Link>

        <Nav.Link className="text-dark">
          <Link to="/blog">
            {translate('blog')} {active === '/blog' && <span className="sr-only">(current)</span>}
          </Link>
        </Nav.Link>
        <Nav.Link className="text-dark">
          <Link to="/contact-us">
            {translate('contact_us')}{' '}
            {active === '/contact-us' && <span className="sr-only">(current)</span>}

          </Link>
        </Nav.Link>
        {/* <form className="form-inline my-2 my-lg-0">
          <Dropdown>
            <Dropdown.Toggle className="my-dropdown" variant="light" id="dropdown-basic" size="sm" >
              {language && language.toUpperCase()}
            </Dropdown.Toggle>

            <Dropdown.Menu >
              <Dropdown.Item
                href="#"
                onClick={() => setLanguage(language === 'ar' ? 'en' : 'ar')}
              >
                {language === 'ar' ? 'EN' : 'AR'}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </form> */}
      </Nav>
    </Navbar.Collapse>
  );

  const renderNav = () => {
    return (
      <nav className="navbar navbar-expand-md navbar-light d-none d-md-flex px-0">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="/navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarContent">
          <img src="/logo-pure.svg" alt="yep-syria-logo" width={85} />
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link className={`nav-link text-dark ${active === '/' && 'active-header'}`} to="/">
                {translate('home')} {active === '/' && <span className="sr-only">(current)</span>}
              </Link>
            </li>
            <li className="nav-item active">
              <Link
                className={`nav-link text-dark ${active === '/jobs' && 'active-header'}`}
                to="/jobs"
              >
                {translate('jobs')}{' '}
                {active === '/jobs' && <span className="sr-only">(current)</span>}
              </Link>
            </li>
            <li className="nav-item active">
              <Link
                className={`nav-link text-dark ${active === '/events' && 'active-header'}`}
                to="/events"
              >
                {translate('events')}{' '}
                {active === '/events' && <span className="sr-only">(current)</span>}
              </Link>
            </li>
            <li className="nav-item active">
              <Link
                className={`nav-link text-dark ${active === '/media' && 'active-header'}`}
                to="/media"
              >
                {translate('media')}{' '}
                {active === '/media' && <span className="sr-only">(current)</span>}
              </Link>
            </li>
            <li className="nav-item active">
              <Link
                className={`nav-link text-dark ${active === '/blog' && 'active-header'}`}
                to="/blog"
              >
                {translate('blog')}{' '}
                {active === '/blog' && <span className="sr-only">(current)</span>}
              </Link>
            </li>
            <li className="nav-item active">
              <Link
                className={`nav-link text-dark ${active === '/contact-us' && 'active-header'}`}
                to="/contact-us"
              >
                {translate('contact_us')}{' '}
                {active === '/contact-us' && <span className="sr-only">(current)</span>}
              </Link>
            </li>
          </ul>
          {/* <form className="form-inline my-2 my-lg-0">
            <Dropdown>
              <Dropdown.Toggle className="my-dropdown" variant="light" id="dropdown-basic" size="sm" >
                {language && language.toUpperCase()}
              </Dropdown.Toggle>

              <Dropdown.Menu >
                <Dropdown.Item
                  href="#"
                  onClick={() => setLanguage(language === 'ar' ? 'en' : 'ar')}
                >
                  {language === 'ar' ? 'EN' : 'AR'}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </form> */}
        </div>
      </nav>
    );
  };

  return (
    <div
      ref={forwardedRef}
      dir={language === 'ar' ? isMobile ? 'ltr' : 'rtl' : isMobile ? 'rtl' : 'ltr'}
      style={language === 'ar' ? { textAlign: 'right' } : { textAlign: 'left' }}

      className="fixed-top py-1 bg-white shadow"
    >
      <div className="container bg-white">
        <Navbar collapseOnSelect className="navbar navbar-expand-lg navbar-light d-flex d-md-none" expand="lg">
          <Navbar.Brand href="/">
            <img src="/logo.svg" alt="yep-syria-logo" width={85} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          {renderDrawer()}
        </Navbar>
        {renderNav()}
      </div>
    </div>
  );
};

export default withRouter(Header);

Header.propTypes = {
  location: PropTypes.object.isRequired,
  forwardedRef: PropTypes.object,
};

Header.defaultProps = {
  forwardedRef: null,
};
