/* eslint-disable import/prefer-default-export */
import { createContext } from 'react';
import config from '../resources/config';

export const LanguageContext = createContext({
  language: config.language,
  active: config.active,
  setLanguage: () => {},
  setActive: () => {},
});
