import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Header from '../../partials/header';
import Footer from '../../partials/footer';

const Container = styled.div`
  overflow-x: hidden;
`;

const Layout = (props) => {
  const header = useRef();
  // const headerHeight = header && header.current && header.current.clientHeight;
  const headerHeight = "5.3rem";

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Container>
        <Header forwardedRef={header} />
        <div style={{ marginTop: headerHeight }}>{props.children}</div>
        <Footer />
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
};

export default Layout;
