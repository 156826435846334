/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from '../../resources/translations';
import { server } from '../../api';

const BoxContainer = styled.div`
  margin: 10px;
  border-width: 1px;
  box-shadow: 0 0px 1px 0px #3e3e3e;
  padding-bottom: 20px;
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Card = styled.div`
  position: absolute;
  top: 75%;
  width: 100%;
  background-color: white;

  padding-top: 20px;
  padding-bottom: 20px;
  transition: 0.3s;
  padding-left: 20px;
  padding-right: 20px;
  height: 25%;
  overflow: hidden;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 430px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const Label = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const Title = styled.h6`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const Content = styled.p``;
const LinkButton = styled.button`
  font-weight: bold;
`;

const Article = ({ meta }) => {
  return (
    <BoxContainer>
      <Link
        to={{
          pathname: `/post/${meta.id}`,
          state: { detail: meta },
        }}
      >
        <Container>
          <Image src={`${server}/${meta.thumbnail_image}`} />
          <Card>
            <Row>
              <Label>
                <i className="far fa-calendar-alt mx-2" />
                <p className="p-0 m-0">{moment(meta.created_at || moment()).format('LL')}</p>
              </Label>
              <Label>
                {meta.author && (
                  <>
                    <i className="fas fa-user mx-2" />
                    <p className="p-0 m-0">{meta.author}</p>
                  </>
                )}
              </Label>
            </Row>
            <Title>{meta.title}</Title>
            <Content>{meta.description}</Content>
          </Card>
        </Container>
      </Link>
      <Link
        to={{
          pathname: `/post/${meta.id}`,
          state: { detail: meta },
        }}
      >
        <LinkButton role="button" className="btn btn-link color-primary mx-3">
          {translate('read_more')}
        </LinkButton>
      </Link>
    </BoxContainer>
  );
};

export default Article;

Article.propTypes = {
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};
