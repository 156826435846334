/* eslint-disable global-require */
import i18n from 'i18n-js';
import memoize from 'lodash.memoize';
import translationGetters from './translations';

i18n.translations = { en: translationGetters.en, ar: translationGetters.ar };

export const translate = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

const selectLanguage = (language) => {
  translate.cache.clear();
  i18n.locale = language;
};

i18n.defaultLocale = 'ar';

export default selectLanguage;

export const defaultLanguages = {
  ENGLISH: {
    languageCode: 'en',
    countryCode: 'US',
    language: 'English (US)',
  },
  ARABIC: {
    languageCode: 'ar',
    countryCode: 'SY',
    language: 'Arabic (SY)',
  },
};
