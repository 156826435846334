/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, Suspense } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { server } from '../../api';
import Carousel from 'react-bootstrap/Carousel'


const pausePerItem = 5;
const SLIDER_HEIGHT = 540;

const Window = styled.div`
  margin-top: 10px;
  width: 100vw;
  position: relative;
  overflow-x: hidden;
`;

const Image = styled.img`
  width: 100%;
  // height: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  // height: 100%;
  position: absolute;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  background: ${({ withContent }) =>
    withContent
      ? 'rgba(0, 0, 0, 0.5)'
      : 'linear-gradient(to top,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0))'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  padding: 10px 20px 10px 20px;
  transition: 0.3s;
  box-shadow: 0 0 20px 1px #1e1e1e;
  ${({ dir }) => (!dir ? '' : `direction: ${dir};`)}
`;

const Subtitle = styled.h5`
  padding: 10px;
  font-weight: normal;
  transition: 0.3s;
`;

const SliderContent = styled.div`
  position: absolute;
  text-align: center;
  transition: 0.3s;
  z-index: 10;
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Dots = styled.div`
  position: absolute;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  transition: 0.3s;
`;

const Dot = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  margin-left: 5px;
  margin-right: 5px;
  background-color: ${({ focus }) => (focus ? 'white' : 'transparent')};
  transition: 0.3s;
  cursor: pointer;
`;

const Placeholder = () => {
  return (
    <ContentLoader
      style={{ width: window.innerWidth, height: SLIDER_HEIGHT }}
      viewBox={`0 0 ${window.innerWidth} ${SLIDER_HEIGHT}`}
    >
      <rect width={window.innerWidth} height={SLIDER_HEIGHT} />
    </ContentLoader>
  );
};

const Slider = ({ dir, blurAndStretch, images, withContent, loading }) => {
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    const animateOverlay = () => {
      if (slide === images.length - 1) {
        setSlide(0);
      } else {
        setSlide(slide + 1);
      }
    };
    const timer = setInterval(animateOverlay, pausePerItem * 1000);
    return () => clearInterval(timer);
  }, [images, slide]);

  const renderSlides = () => {
    return (
      <Carousel wrap={true} interval={null}>
        {
          images.map(({ id, image, title, subtitle }, index) => {
            return (
              <Carousel.Item interval={500} >
                <img
                  className="d-block w-100"
                  src={`${server}/${image}`}
                  alt="Second slide"
                />
                {/* <Carousel.Caption>
            <h3>{subtitle}</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
              </Carousel.Item>
            );
          })}
      </Carousel>
    );
  };

  return (
    <Suspense fallback={<Placeholder />}>
      <Window>
        {images.length > 0 && renderSlides()}
        {loading ? <Placeholder /> : <Overlay withContent={withContent} />}
        {/* {images.length > 1 && (
          <Dots>
            {images.map((_, index) => (
              <Dot onClick={() => setSlide(index)} key={_.id} focus={slide === index} />
            ))}
          </Dots>
        )} */}
      </Window>
    </Suspense>
  );
};

export default Slider;

Slider.propTypes = {
  images: PropTypes.array.isRequired,
  withContent: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  dir: PropTypes.string,
  blurAndStretch: PropTypes.bool,
};
Slider.defaultProps = {
  withContent: false,
  dir: null,
  blurAndStretch: false,
};
