/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { LanguageContext } from '../../contexts';

import variables from '../../styles/variables.scss';
import { server } from '../../api';
import { translate } from '../../resources/translations';

const elemWidth = 270;
const margin = 60;

const Post = styled.div`
  width: ${elemWidth}px;
  height: ${elemWidth}px;
  margin-left: ${margin}px;
  margin-right: ${margin}px;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const Lazy = styled(LazyLoadImage)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100vw;
  transition: 0.3s;
`;

const Header = styled.h6`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  color: white;
  padding: 10px;
  font-size: 2vmin;
  font-weight: bold;
  transition: 0.3s;
  z-index: 1;
  text-align: ${({ dir }) => (dir === 'rtl' ? 'right' : 'left')};
`;

const Control = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${variables.primary};
  border: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  width: 5em;
  transition: 0.3s;
  opacity: 0.5;
  color: white;
`;

const FixedHeight = styled.div`
  height: 400px;
`;



const CustomItem = styled.div`
  margin: 3px 10px 3px 10px;
`;

const Tag = styled.h6`
  position: absolute;
  top: 24%;
  left: 0px;
  padding: 3px 10px 3px 10px;
  border-radius: 0px 10px 10px 0px;
  background-color: white;
  box-shadow: 0px 2px 10px 1px #aaa;
  opacity: 0.6;
`;

const Details = styled.h6`
  position: absolute;
  bottom: -3px;
  right: 9px;
  padding: .25em .8em;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 10px 1px #aaa;
  opacity: 0.6;
  font-size: .9em;
`;

const gradientBasedOnType = (type) => {
  let startColor;
  let endColor;
  switch (type) {
    case 'event':
      startColor = '#FECE31';
      endColor = '#F6911F';
      break;
    case 'blog':
      startColor = '#30A5DD';
      endColor = '#096EB6';
      break;
    case 'media':
      startColor = '#D07746';
      endColor = '#EE4B64';
      break;
    default:
      startColor = '#7DC797';
      endColor = '#08A755';
  }

  return `linear-gradient(to right, ${startColor}, ${endColor})`;
};

const Placeholder = () => {
  return (
    <ContentLoader
      style={{ width: elemWidth, height: elemWidth }}
      viewBox={`0 0 ${elemWidth} ${elemWidth}`}
    >
      <rect width={elemWidth} height={elemWidth} />
    </ContentLoader>
  );
};

const Posts = ({ data, loading }) => {
  const { language } = useContext(LanguageContext);
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const [visibleCount, setVisibleCount] = useState(0);
  const [pause, setPause] = useState(false);
  const scroll = useRef(null);
  const [scrollPos, setScrollPos] = useState(0);

  const settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    speed: 500,
    draggable: true,
    touch: true,
    swipeToSlide: true,
    swipe: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1370,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  useEffect(() => {
    setVisibleCount(
      // eslint-disable-next-line no-undef
      parseInt((data.length * (elemWidth + 2 * margin) - window.innerWidth) / elemWidth, 10)
    );
  }, [data]);


  return (

    <div>
      <Slider {...settings}>
        {data.map((post) => (
          <div>

            <Post className="py-0 post" dir={dir} key={post.id}>
              <Lazy
                width={elemWidth}
                height={elemWidth}
                effect="blur"
                src={`${server}/${post.thumbnail_image}`}
              />
              <Header
                dir={dir}
                className="text-light my-0 post--header"
                style={{ background: gradientBasedOnType(post.type) }}
              >
                {post.title}
              </Header>
              <Tag>{translate(`${post.type}_tag`)}</Tag>
              <Details>
                <Link
                  to={{
                    pathname: `/post/${post.id}`,
                    state: { detail: post },
                  }}
                > التفاصيل
                </Link>
              </Details>
            </Post>
          </div>
        ))}
      </Slider>
    </div>

  );
};

export default Posts;

Posts.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
