/* eslint-disable no-undef */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';

import localization from 'moment/locale/ar';
import { LanguageContext } from './contexts';
import config from './resources/config';
import setI18nConfig, { translate } from './resources/translations';

import Home from './pages/index';
import Blog from './pages/blog';
import Layout from './components/Layout';

import './styles/index.scss';
import Jobs from './pages/jobs';
import Job from './pages/jobs/details';
import Events from './pages/events';
import Medias from './pages/media';
import Contactus from './pages/contact-us';
import Post from './pages/post';
import Branch from './pages/branch';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyApp = () => {
  const [language, changeLanguage] = useState(config.language);
  const [active, setActive] = useState(config.active);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const setLanguage = (lang) => {
    window.localStorage.setItem('yep/language', lang);
    setI18nConfig(lang);
    changeLanguage(lang);
    moment.locale(lang, localization);
    forceUpdate();
  };

  useEffect(() => {
    const lang = window.localStorage.getItem('yep/language');
    setI18nConfig(lang || 'ar');
    changeLanguage(lang || 'ar');
    moment.locale(lang || 'ar', localization);
    forceUpdate();
  }, []);

  return (
    <LanguageContext.Provider value={{ language, active, setLanguage, setActive }}>
      <Router>
        <Layout active={active} title={translate('home_header_title')}>
          <Switch>
            {/* <Route path="/blog/:id" component={Article} /> */}
            <Route path="/blog" exact component={Blog} />
            <Route path="/jobs/:id" exact component={Job} />
            <Route path="/jobs" exact component={Jobs} />
            {/* <Route path="/events/:id" exact component={Event} /> */}
            <Route path="/events" exact component={Events} />
            {/* <Route path="/media/:id" exact component={Media} /> */}
            <Route path="/post/:id" exact component={Post} />
            <Route path="/branch/:id" exact component={Branch} />

            <Route path="/media" exact component={Medias} />
            <Route path="/contact-us" exact component={Contactus} />
            <Route path="/" exact component={Home} />
          </Switch>
        </Layout>

      </Router>
    </LanguageContext.Provider>
  );
};

export default MyApp;

MyApp.propTypes = {};
