// eslint-disable-next-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import moment from 'moment';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
// eslint-disable-next-line import/no-webpack-loader-syntax
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import styled from 'styled-components';
import API from '../../api';
import { LanguageContext } from '../../contexts';
import MarkerUrl from './marker.png';

// const Marker = ({ text }) => <div><img width="30" src={marker} /></div>;

const Icon = styled.img`
  height: 32px;
  width: 32px;
  object-fit: contain;
  margin-inline-end: 8px;
  filter: hue-rotate(179deg);
`;
const MyPlace = styled.div``;
const MapContainer = styled.div`
  width: 100%;
  height: 50vh;
  overflow: hidden;
`;


const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoibWFqZGFsYWpsYW5pIiwiYSI6ImNraTYxdHRrNDJnOXIycnFxbXczODg0cTUifQ.pOaFaZ4j8HvM-JisZd7BvA'
});
// MapboxGl.accessToken =
//   'pk.eyJ1IjoibWFqZGFsYWpsYW5pIiwiYSI6ImNraTYxdHRrNDJnOXIycnFxbXczODg0cTUifQ.pOaFaZ4j8HvM-JisZd7BvA';

export default function Branch(props) {
  const mapRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = useState(null);
  const [branch, setBranch] = useState({});
  const [loading, setLoading] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const { language } = useContext(LanguageContext);
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  useLayoutEffect(() => {
    // eslint-disable-next-line react/prop-types
    API.website.branch(props.match.params.id).then(async (_branch) => {
      setBranch(_branch);
      setLat(_branch.latitude);
      setLng(_branch.longitude);
    });
  }, []);

  return (
    <Container dir={dir} style={{ marginTop: 148, marginBottom: 148 }}>
      <Row>
        <div
          className="col-md-7 col-12 text-right"
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
          {Object.keys(branch).length ? (
            <>
              <h2>فرع : {branch.city.name}</h2>
              <h3>
                أوقات الدوام:
                {` `}
                {moment(`2020-12-01 ${branch.start_time}`).format('LT')}
                {` - `}
                {moment(`2020-12-01 ${branch.end_time}`).format('LT')}
              </h3>
              <h4>
                <Icon src="/assets/imgs/pin-orange.png" />

                العنوان:
                {` `}
                {branch.address}
              </h4>
              <h4>
                <Icon src="/assets/imgs/phone-orange.png" />
                رقم الهاتف:
                {` `}
                {branch.phone}
              </h4>
              <h4>
                <Icon src="/assets/imgs/mail-orange.png" />

                البريد الالكتروني :{` `}
                {branch.email}
              </h4>
            </>
          ) : null}
        </div>
        <div
          className="col-md"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <MapContainer>
            <Map
              style="mapbox://styles/mapbox/streets-v9"
              center={[lng, lat]}
              zoom={[8]}
              containerStyle={{
                height: '100%',
                width: '100%'
              }}
            >
              <Marker
                coordinates={[lng , lat ]}
                anchor="bottom">
                <img with={30} height={30} src={MarkerUrl} />
              </Marker>

            </Map>;
          </MapContainer>
        </div>
      </Row>
    </Container>
  );
}
