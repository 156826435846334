import React, { Suspense, useContext, useEffect, useState } from 'react';

import { Facebook } from 'react-content-loader';
import PropTypes from 'prop-types';
import { data } from 'jquery';
import styled from 'styled-components'
import Searchbar from '../../components/Searchbar';
import { LanguageContext } from '../../contexts';
import JobsResults from '../../components/Jobs';
import SideFilter from '../../components/SideFilter';
import { translate } from '../../resources/translations';

import { ReactComponent as DownloadAppStore } from '../../assets/app-store-download.svg';
import { ReactComponent as DownloadPlayStore } from '../../assets/google-play-download.svg';

import JobContext from './context';

import YEP from '../../api';


const LookingForJob = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin : 2em 0;
  gap: 1em;
`

const Jobs = ({ ...props }) => {
  const { language } = useContext(LanguageContext);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [per_page, setPerPage] = useState(5);
  const [total, setTotal] = useState(5);
  const [all, setAll] = useState(0);

  const dir = language === 'ar' ? 'rtl' : 'ltr';
  useEffect(() => {
    const getJobs = async () => {
      setLoading(true);
      const { data: _jobs } = await YEP.website.jobs.all(props.location.state);
      setCurrentPage(_jobs.current_page);
      setTotal(_jobs.last_page);
      setJobs(_jobs.data);
      setLoading(false);
      setAll(_jobs.total);
    };

    getJobs();
  }, []);

  const updateJobs = async (page, filter) => {
    setLoading(true);
    const { data: _jobs } = await YEP.website.jobs.all(page, filter);
    setJobs(_jobs.data);
    setTotal(_jobs.last_page);
    setLoading(false);
    setAll(_jobs.total);
  };

  const updatePage = async (page) => {
    setLoading(true);
    const { data: _jobs } = await YEP.website.jobs.all(page);
    setJobs(_jobs.data);
    setTotal(_jobs.last_page);
    setCurrentPage(_jobs.current_page);
    setLoading(false);
    setAll(_jobs.total);
  };
  return (
    <JobContext.Provider value={{ loading }}>
      <Suspense fallback={Facebook}>
        <div className="container-fluid">
          {/* <Searchbar dir={dir} companyName jobType workLocation /> */}
          <div className="row">
            <JobsResults
              dir={dir}
              data={jobs}
              max={total}
              onChangePage={updatePage}
              pagination
              all={all}
            />
            <SideFilter dir={dir} page={currentPage} onChangeFilter={updateJobs} />
          </div>
        </div>
        <LookingForJob>
          <h3 >{translate('looking_for_job')}</h3>
          {translate('download_our_app')}
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1em' }}>
            {/* <DownloadAppStore width={200} height={200} /> */}
            <a style={{ position: 'relative', top: -50 }} href='https://play.google.com/store/apps/details?id=com.epic.yep' target="_blank">
              <DownloadPlayStore width={200} height={200} />
            </a>
          </div>
        </LookingForJob>
      </Suspense>
    </JobContext.Provider>
  );
};

export default Jobs;

Jobs.propTypes = {
  location: PropTypes.object,
};
Jobs.defaultProps = {
  location: null,
};
