/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown, Pagination, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { translate } from '../../resources/translations';
import variables from '../../styles/variables.scss';
import { server } from '../../api';
import JobContext from '../../pages/jobs/context';

const Container = styled.div`
  border-width: 1px;
  border-color: ${`${variables.border}80`};
  border-style: solid;
  border-radius: 15px;
  margin: 20px 18px 20px auto;
`;
const SummaryContainer = styled.div`
  background-color: ${variables.secondary};
  border-radius: 15px 15px 0 0;
  border-bottom: 1px ${variables.border} solid;
  padding: 10px 0 10px 0;
`;

const ResultsContainer = styled.div`
  border-radius: 0 0 15px 15px;
`;

const ResultContainer = styled.div`
  &:hover {
    background-color: ${variables.secondary};
  }
  transition: 0.3s;
`;
const JobTitle = styled.h4`
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: ${variables.primary};
    text-decoration: underline;
  }
`;

const Result = ({ data }) => {
  return (
    <ResultContainer className="row align-items-center justify-content-end text-right py-4">
      <div className="col-md-8 col-sm-12 text-right">
        <Link
          to={{
            pathname: `/jobs/${data.id}`,
            state: { detail: data },
          }}
        >
          <JobTitle>{data.title}</JobTitle>
        </Link>
        <p className="text-disabled">{data.type}</p>
        <div>
          <i className="fas fa-map-marker-alt mx-2 " />
          <p className="text-disabled d-inline-block">{data.city.name}</p>
        </div>
        <div>
          {data.company && data.company.email && (
            <a href={`mailto:${data?.company?.email}`}>
              <p  className="d-inline-block text-primary">{translate('job_email')}</p>
              <i className="far fa-envelope mx-2 text-primary" />
            </a>
          )}
          {/* <a href="/">
            <p className="d-inline-block text-primary">{translate('save_job')}</p>
            <i className="far fa-heart mx-2 text-primary" />
          </a> */}
        </div>
      </div>
      <div className='col-md-4 col-sm-12'>
        <img width={220} height={220} src={`${server}/${data.company && data.company.logo}`} alt="job logo" />
      </div>
    </ResultContainer>
  );
};

Result.propTypes = {
  data: PropTypes.object.isRequired,
};

const Results = ({ max, pagination, data, onChangePage }) => {
  const [subData, setSubData] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setSubData(data.paginate(max, page));
    setTotalPages(max);
  }, [data, page, max]);

  useEffect(() => {
    setPages([...Array.from(Array(totalPages)).keys()]);
  }, [totalPages]);

  useEffect(() => {
    onChangePage(page);
  }, [page]);

  return (
    <ResultsContainer className="container">
      {(pagination ? data : data).map((result, index) => (
        <React.Fragment key={result.id}>
          <Result data={result} />
          {index !== data.length - 1 && <hr />}
        </React.Fragment>
      ))}
      {pagination && (
        <div className="w-100 d-flex flex-row align-items-center justify-content-center">
          <Pagination>
            <Pagination.First disabled={page === 1} onClick={() => setPage(1)} />
            <Pagination.Prev disabled={page === 1} onClick={() => setPage(page - 1)} />
            {pages.slice(page, page + 5).map((p) => (
              <Pagination.Item
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  window.scrollTo(0, 0);
                  setPage(p + 1);
                }}
                active={page === p + 1}
                key={p}
              >
                {p + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === pages.length} />
            <Pagination.Last
              onClick={() => setPage(pages.length)}
              disabled={page === pages.length}
            />
          </Pagination>
        </div>
      )}
    </ResultsContainer>
  );
};

Results.propTypes = {
  max: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.bool.isRequired,
};

const Summary = ({ count, dir }) => {
  return (
    <SummaryContainer className="row justify-content-around align-items-center" dir={dir}>
      <p className="pb-0 mb-0">{`${translate('found')} ${count} ${translate('job')}`}</p>
    </SummaryContainer>
  );
};

Summary.propTypes = {
  count: PropTypes.number.isRequired,
  dir: PropTypes.string.isRequired,
};

const Jobs = ({ data, dir, max, pagination, onChangePage, all }) => {
  const { loading } = useContext(JobContext);
  return (
    <Container className="container col-md-6 col-11">
      {data && <Summary dir={dir} count={all} filter />}
      {loading && (
        <div className="w-100 d-flex flex-row align-items-center justify-content-center">
          <Spinner className="m-5" variant="primary" animation="grow" />
        </div>
      )}
      {!loading && data.length === 0 && (
        <h4 className="text-center mt-4">{translate('no_results')}</h4>
      )}
      {data.length && (
        <Results max={max} data={data} pagination={pagination} onChangePage={onChangePage} />
      )}
    </Container>
  );
};

export default Jobs;

Jobs.propTypes = {
  data: PropTypes.array.isRequired,
  max: PropTypes.number,
  pagination: PropTypes.bool,
  dir: PropTypes.string.isRequired,
};

Jobs.defaultProps = {
  max: 10,
  pagination: true,
};
