import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

import Slides from '../../components/Slider';
import Article from '../../components/Article';

import YEP from '../../api';
import { LanguageContext } from '../../contexts';

const Section = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: ${({ dir }) => (dir === 'rtl' ? 'right' : 'left')};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  text-align: ${({ dir }) => (dir === 'rtl' ? 'right' : 'left')};
`;

const Blog = () => {
  const [sliders, setSliders] = useState([]);
  const [posts, setPosts] = useState([]);
  const { language } = useContext(LanguageContext);
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadArticles = async () => {
      setLoading(true);
      const { data } = await YEP.website.media.slider();
      const { data: dataPosts } = await YEP.website.media.articles();

      setSliders(data);
      setPosts(dataPosts);
      setLoading(false);
    };
    loadArticles();
  }, []);

  return (
    <>
      <Slides loading={loading} images={sliders} withContent />

      <Section />

      <Grid dir={dir}>
        {posts?.data?.map((p) => (
          <Article type="media" key={p.id} meta={p} />
        ))}
      </Grid>
    </>
  );
};

export default Blog;
