import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Container, Dropdown, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { LanguageContext } from '../../contexts';
import { translate } from '../../resources/translations';
import YEP from '../../api';

const schema = yup.object({
  email: yup.string().trim().email("الرجاء إدخال بريد الكتروني صحيح").required("هذا الحقل مطلوب"),
  name: yup.string().trim().required("هذا الحقل مطلوب"),
  mobile_number: yup.string("الرجاء إدخال رقم هاتف صحيح").required("هذا الحقل مطلوب"),
  message: yup.string().required("هذا الحقل مطلوب"),
  city_id: yup.number().required("هذا الحقل مطلوب"),
});

const ContactUs = () => {
  const { language } = useContext(LanguageContext);
  const [intro, setInro] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const getContent = useCallback(() => intro.find((o) => o.key === 'content').value, [intro]);

  const dir = language === 'ar' ? 'rtl' : 'ltr';

  const submitContactUsForm = async (fields, { setSubmitting }) => {
    setLoading(true);
    try {
      await YEP.website.contactus(fields);
      // eslint-disable-next-line no-undef
      alert('تم الارسال بنجاح');
    } catch (e) {
      // eslint-disable-next-line no-undef
      alert('حدث خطأ ما يرجى المحاولة مرة أخرى');
    }
    setLoading(false);
    setSubmitting(false);
  };

  useEffect(() => {
    const loadData = async () => {
      const { data: _intro } = await YEP.website.promo();
      const _cities = await YEP.website.cities();

      setCities(_cities);
      setInro(_intro);
    };
    loadData();
  }, []);

  return (
    <Container dir={dir} style={{ marginTop: 148, marginBottom: 148 }}>
      <Row>
        <div className="col-md text-right">
          <img className="col-md" alt="Logo" src="/logo.svg" width={500} />
          <h1>{translate('home_header_title')}</h1>
          <p>{intro.length > 0 && getContent()}</p>
        </div>
        <Formik
          validationSchema={schema}
          validateOnChange={false}
          initialValues={{
            email: '',
            name: '',
            mobile_number: '',
            city_id: '',
            message: '',
          }}
          onSubmit={submitContactUsForm}
        >
          {({ handleSubmit, values, errors, handleChange }) => (
            <Form className="col-md-7 col-12 text-right" noValidate onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>{translate('email')}</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{translate('full_name')}</Form.Label>
                <Form.Control
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <small>{!!errors.name}</small>
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{translate('mobile_number')}</Form.Label>
                <Form.Control
                  isInvalid={!!errors.mobile_number}
                  name="mobile_number"
                  value={values.mobile_number}
                  onChange={handleChange}
                  dir={dir}
                />
                <Form.Control.Feedback type="invalid">{errors.mobile_number}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{translate('choose_city')}</Form.Label>
                <Form.Control
                  isInvalid={!!errors.city_id}
                  as="select"
                  name="city_id"
                  value={values.city_id}
                  onChange={handleChange}
                  custom
                >
                  <option value="">{translate('choose_city')}</option>
                  {cities.map((x) => (
                    <option key={`city-${x.id}`} value={x.id}>
                      {x.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.city_id}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>{translate('message')}</Form.Label>
                <Form.Control
                  isInvalid={!!errors.message}
                  name="message"
                  as="textarea"
                  rows={10}
                  value={values.message}
                  onChange={handleChange}
                  dir={dir}
                  placeholder=""
                />
                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
              </Form.Group>

              <Button disabled={isLoading} block variant="primary" type="submit">
                {isLoading ? '.....' : translate('submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default ContactUs;
