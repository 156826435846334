/* eslint-disable no-undef */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, Suspense, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from '../../resources/translations';
import Slides from '../../components/PostSlider';
import { LanguageContext } from '../../contexts';
import { FacebookShareButton, FacebookIcon ,TwitterShareButton, TwitterIcon } from 'react-share'

import API from '../../api';

const Section = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: ${({ dir }) => (dir === 'rtl' ? 'right' : 'left')};
`;

const Content = styled.pre`
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  line-height: 40px;
  text-align: right;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  border-width: 5px;
  border-color: rgba(125, 125, 125, 0.1);
  border-style: solid;
  padding: 25px;
  font-family : inherit;
`;

const Title = styled.h3`
  text-align: center;
  font-weight: bold;
  opacity: 0.5;
`;

const SocialButtons = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap : .5em;
  justify-content: center;
  i {
    font-size: 5vmin;
  }
`;

function Post({ ...props }) {
  const [post, setPost] = useState(null);
  const { language } = useContext(LanguageContext);
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const [loading, setLoading] = useState(false);
  const shareUrl = window.location.href;

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const loadPost = async () => {
      const {
        params: { id },
      } = props.match;
      setPost(await API.website.post(id));
      setLoading(false);
    };
    loadPost();
  }, []);

  return (
    <Suspense fallback={<div>loading...</div>}>
      <>
        <Slides
          dir={dir}
          blurAndStretch
          images={
            post
              ? post.images.map((image) => ({
                id: image.id,
                image: image.image,
                title: post.title,
                subtitle: `<div style="display:inline-block;direction: ${dir}; padding:4px; background-color:#FFFFFFCC;color:black;">${moment(
                  post.created_at || moment()
                ).format('LL')}</div>
                         ${post.author
                    ? `<br/><div style="display:inline-block;direction: ${dir}; padding:4px; margin-top: 4px; background-color:#FFFFFFCC;color:black;">${post.author}</div>`
                    : ''
                  }`,
              }))
              : []
          }
          loading={loading}
          withContent
        />
        <Section>
          <Content dir={dir} dangerouslySetInnerHTML={{ __html: post && post.body }} />
        </Section>
        <Section>
          <Title>{translate('share_this_article')}</Title>
          <SocialButtons>
            <FacebookShareButton url={shareUrl}   >
             <FacebookIcon size={45} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={45} round={true}/>
            </TwitterShareButton>
          </SocialButtons>
        </Section>
      </>
    </Suspense>
  );
}

export default Post;

Post.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
