/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { translate } from '../../resources/translations';
import variables from '../../styles/variables.scss';

import YEP from '../../api';

const Container = styled.div`
  border-radius: 15px;
  background-color: ${variables.section};
  margin: 20px auto 20px 0;
  padding: 15px 10px 15px 10px;
  border-width: 1px;
  border-color: ${`${variables.border}80`};
  border-style: solid;
`;
const Section = styled.div`
  margin-top: 30px;
`;

const Tag = styled.span`
  padding: 7px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${({ active }) => (active ? variables.primary : variables.disabled)};
  color: ${({ active }) => (active ? 'white' : 'black')};
  margin: 5px;
  transition: 0.3s;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ active }) => (active ? 'twitter' : variables.disabled)};
  &:hover {
    border-width: 2px;
    border-style: solid;
    border-color: ${({ active }) => (active ? 'twitter' : variables.border)};
  }
`;

const SideFilter = ({ dir, page, onChangeFilter }) => {
  const time = useRef([1, 3, 7, 30]).current;
  const [selectedTime, setSelectedTime] = useState(-1);
  
  const [filters, setFilters] = useState({
    job_types: [],
    job_categories: [],
    cities: [],
  });
  const [filterString, setFilterString] = useState({
    job_types: [],
    job_categories: [],
    city_id: [],
    announcement_date: null,
  });

  useEffect(() => {
    const init = async () => {
      const { data: _filters } = await YEP.website.jobs.filters();
      setFilters(_filters);
    };
    init();
  }, []);

  useEffect(() => {
    onChangeFilter(page, filterString);
  }, [filterString]);

  return (
    <Container dir={dir} className="col-md-3 d-none d-md-block ml-1">
      <Section className="mt-0">
        <h6 className="text-right">{translate('ad_date')}</h6>
        <hr />
        <Form className="text-right">
          <Form.Check
            checked={selectedTime === time[0]}
            type="radio"
            id="filter_time_1"
            label={translate('filter_time_1')}
            onChange={() => {
              setSelectedTime(1);
              setFilterString({ ...filterString, announcement_date: 1 });
            }}
          />
          <Form.Check
            checked={selectedTime === time[1]}
            type="radio"
            label={translate('filter_time_2')}
            id="filter_time_2"
            onChange={() => {
              setSelectedTime(3);
              setFilterString({ ...filterString, announcement_date: 3 });
            }}
          />
          <Form.Check
            checked={selectedTime === time[2]}
            type="radio"
            label={translate('filter_time_3')}
            id="filter_time_3"
            onChange={() => {
              setSelectedTime(7);
              setFilterString({ ...filterString, announcement_date: 7 });
            }}
          />
          <Form.Check
            checked={selectedTime === time[3]}
            type="radio"
            label={translate('filter_time_4')}
            id="filter_time_4"
            onChange={() => {
              setSelectedTime(30);
              setFilterString({ ...filterString, announcement_date: 30 });
            }}
          />
        </Form>
      </Section>
      <Section>
        <h6 className="text-right">{translate('job_type')}</h6>
        <hr />
        <Form className="text-right">
          {filters.job_types &&
            filters.job_types.map((jt) => (
              <Form.Check
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterString({
                      ...filterString,
                      job_types: [...filterString.job_types, jt.id],
                    });
                  } else {
                    const newArray = [...filterString.job_types];
                    newArray.splice(newArray.indexOf(jt.id), 1);
                    setFilterString({
                      ...filterString,
                      job_types: [...newArray],
                    });
                  }
                }}
                id={`filter_job_type_${jt.id}`}
                key={jt.id}
                type="checkbox"
                label={jt.name}
              />
            ))}
        </Form>
      </Section>
      <Section>
        <h6 className="text-right">{translate('job_category')}</h6>
        <hr />
        <Form className="text-right">
          {filters.job_categories &&
            filters.job_categories.map((jt) => (
              <Form.Check
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterString({
                      ...filterString,
                      job_categories: [...filterString.job_categories, jt.id],
                    });
                  } else {
                    const newArray = [...filterString.job_categories];
                    newArray.splice(newArray.indexOf(jt.id), 1);
                    setFilterString({
                      ...filterString,
                      job_categories: [...newArray],
                    });
                  }
                }}
                id={`filter_job_category_${jt.id}`}
                key={jt.id}
                type="checkbox"
                label={jt.name}
              />
            ))}
        </Form>
      </Section>
      <Section>
        <h6 className="text-right">{translate('salary')}</h6>
        <hr />
        <Form className="text-right">
          <Form.Control type="number" placeholder={translate('from')} />
          <Form.Control type="number" className="mt-1" placeholder={translate('to')} />
        </Form>
      </Section>
      <Section>
        <h6 className="text-right">{translate('location')}</h6>
        <hr />
        <div className="d-flex flex-row flex-wrap">
          {filters.cities.map((c) => (
            <Tag
              active={filterString.city_id.indexOf(c.id) >= 0}
              onClick={() => {
                if (filterString.city_id.indexOf(c.id) < 0) {
                  setFilterString({
                    ...filterString,
                    city_id: [...filterString.city_id, c.id],
                  });
                } else {
                  const newArray = [...filterString.city_id];
                  newArray.splice(newArray.indexOf(c.id), 1);
                  setFilterString({
                    ...filterString,
                    city_id: [...newArray],
                  });
                }
              }}
              key={c.id}
            >
              {c.name}
            </Tag>
          ))}
        </div>
      </Section>
    </Container>
  );
};

SideFilter.propTypes = {
  dir: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default SideFilter;
