import React, { useEffect, useState, useContext, useCallback, useRef, Suspense } from 'react';
import styled from 'styled-components';
import { translate } from '../resources/translations';
import parse from "html-react-parser";
import { useHistory } from 'react-router-dom'
import Slides from '../components/Slider';
import Posts from '../components/Posts';
import Video from '../components/Video';
import { Formik } from 'formik';
import * as yup from 'yup';

import YEP, { server } from '../api';
import { LanguageContext } from '../contexts';
import Syria from '../components/Syria';

const Section = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: ${({ dir }) => (dir === 'rtl' ? 'right' : 'left')};
`;

const Title = styled.h3`
  text-align: center;
  margin-top : 2rem;
  margin-bottom : 2rem;
`;

const Vr = styled.div`
  border-left: 1px solid #e3e3e3;
  height: 500px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));;
  text-align: ${({ dir }) => (dir === 'rtl' ? 'right' : 'left')};
`;

const StatsSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
`
const StatsItem = styled.div`
  width : 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const FieldWrapper = styled.div`
  width : 46%;
  min-width: 300px; 
  display : flex;
  flex-direction : column;
  gap : 5px;
`
const FormWrapper = styled.div`
  display : flex;
  flex-wrap : wrap;
  justify-content:center;
  gap: 1em;
`

const ErrorMessage = styled.small`
  color : #FF9494;
  text-align: right;
`

const schema = yup.object({
  email: yup.string().trim().email("الرجاء إدخال بريد الكتروني صحيح").required("هذا الحقل مطلوب"),
  name: yup.string().trim().required("هذا الحقل مطلوب"),
});

function Home() {
  const history = useHistory();
  const [sliders, setSliders] = useState([]);
  const [posts, setPosts] = useState([]);
  const [intro, setInro] = useState([]);
  const [branches, setBranches] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);
  const [fullName, setFullName] = useState('');
  const [cities, setCities] = useState([]);

  const [email, setEmail] = useState('');
  const { language } = useContext(LanguageContext);

  const dir = language === 'ar' ? 'rtl' : 'ltr';

  const getVideo = useCallback(() => intro.find((o) => o.key === 'video').value, [intro]);
  const getContent = useCallback(() => intro.find((o) => o.key === 'content').value, [intro]);

  const submitForm = async (fields , {setSubmitting , resetForm }) => {
    await YEP.website.subscribeEmail(fields);
    // eslint-disable-next-line no-undef
    alert('تم تسجيل بريدك الالكتروني ضمن القائمة البريدية');
    setSubmitting(false);
    resetForm();
  };

  useEffect(() => {
    const loadSlides = async () => {
      setLoading(true);
      const { data } = await YEP.website.slider();
      const { data: dataPosts } = await YEP.website.news();
      const { data: _intro } = await YEP.website.promo();
      const { data: _provinces } = await YEP.website.provinces();
      const { data: _stats } = await YEP.website.stats();
      const _cities = await YEP.website.cities();

      setInro(_intro);
      setSliders(data);
      setPosts(dataPosts);
      setBranches(_provinces);
      setStats(_stats);
      setCities(_cities);
    };
    loadSlides();
  }, []);

  useEffect(() => {
    if (branches) {
      setLoading(false);
    }
  }, [branches]);

  return (
    <Suspense fallback={<div>loading...</div>}>
      
      <Slides images={sliders} loading={loading} />

      <Section className="bg-section">
        <Title >{translate('new_in_yep')}</Title>
        <Posts data={posts} loading={loading} />
      </Section>

      <div className="py-5 col-12 bg-section">
        <Section dir={dir} className="container">
          <div className="row">
            <div className="col-md">
              <h1>{translate('home_header_title')}</h1>
              <p>{intro.length > 0 && getContent()}</p>
            </div>
            <div className="col-md-6 col-sm-12">
              <img width="100%" height="100%" style={{borderRadius : 10}} src="/assets/imgs/home_about.jpg"/>

            </div>
            {/* {intro.length > 0 && (
              <Video className="col-md-6 col-sm-12" src={`${server}/${getVideo()}`} />
            )} */}
          </div>
        </Section>
      </div>
      <Section className="bg-media py-5">
        <div className="container">
          <StatsSection>
            <StatsItem>
              <img alt="guidance" src="/assets/imgs/Education.svg" className="my-5" width={120} />
              <h4 className="text-center text-light">{translate('education_message')}</h4>
              <h4 className="text-center text-light">
                {translate('education_message', { locale: language === 'en' ? 'ar' : 'en' })}
              </h4>
            </StatsItem>
            <StatsItem>
              <img alt="jobs" src="/assets/imgs/Entrepreneurship.svg" className="my-5" width={120} />
              <h4 className="text-center text-light">{translate('enterpreneurship_message')}</h4>
              <h4 className="text-center text-light">
                {translate('enterpreneurship_message', { locale: language === 'en' ? 'ar' : 'en' })}
              </h4>
            </StatsItem>
            <StatsItem>
              <img alt="money" src="/assets/imgs/skills.svg" className="my-5" width={120} />

              {language === 'ar' &&
                <h4 className="text-center text-light">{translate('skills_message', { locale: 'ar' })}</h4>
              }

              <h4 className="text-center text-light">
                {translate('skills_message1', { locale: 'en' })}
              </h4>
              <h4 className="text-center text-light">
                {translate('skills_message2', { locale: 'en' })}
              </h4>

              {language === 'en' &&
                <h4 className="text-center text-light">{translate('skills_message', { locale: 'ar' })}</h4>
              }
            </StatsItem>
          </StatsSection>
        </div>
      </Section>

      {stats && (
        <Section className="bg-event py-5">
          <div className="container">
            <StatsSection>
              {stats?.map((s, index) => (
                <StatsItem key={s.id} >
                  <img alt="jobs" src={`/assets/imgs/yep${index + 1}.svg`} className="my-5" width={90} />
                  <h4 className="text-center text-light font-weight-bold">
                    {s.key}
                  </h4>
                  <h5 className="text-center text-light font-weight-normal mt-3">{s.value}</h5>
                </StatsItem>
              ))}
            </StatsSection>
          </div>
        </Section>
      )}

      <div className="col-12 bg-section">
        <Section>
          <div className="container py-5">
            <div className="row">
              <Syria
                className="col-md"
                loading={loading}
                provinces={branches}
                lang={language}
                width={437}
                height={402}
              />
              <div dir={dir} className="col-md">
                <div className="row text-primary mb-2">
                  {/* <i className="fas fa-map-marker-alt mx-2" /> */}
                  <h6 className='text-center'>{translate('choose_city')}</h6>
                </div>
                <Grid dir={dir}>
                  {cities
                    .filter((x) => branches.find((y) => y.city_id === x.id))
                    .map((c) => (
                      <div key={`city-${c.id}`} className="mx-5 my-1">
                        <a href={`/branch/${branches.find((y) => y.city_id === c.id).id}`}>{translate(`city_${c.id}`)}</a>
                      </div>
                    ))}
                </Grid>
              </div>
            </div>
           <button onClick={() => history.push('/events')} type="button" className="btn btn-primary text-light d-block mx-auto">
              {translate('explore_activities')}
              <i className="fas fa-compass ml-2" />
            </button>
          </div>
        </Section>
      </div>

      <Section className="bg-primary">
        <div className="container py-5">
          <div className="row align-items-center justify-content-center mb-4 text-light">
            <h3 className='text-center' >{translate('subscribe_message')}</h3>
          </div>

          <Formik
            validationSchema={schema}
            validateOnChange={false}
            initialValues={{
              email: '',
              name: '',
            }}
            onSubmit={submitForm}
          >
            {({ handleSubmit, values, errors, handleChange }) => (

              <form
                onSubmit={handleSubmit}
              >
                <FormWrapper>
                  <FieldWrapper>
                    <input
                      className="form-control mb-2 mx-sm-2 col-md"
                      placeholder={translate('email')}
                      value={values.email}
                      onChange={handleChange}
                      name='email'
                      dir={dir}
                    />
                    <ErrorMessage> {!!errors.email && errors.email} </ErrorMessage>

                  </FieldWrapper>

                  <FieldWrapper>
                    <input
                      name='name'
                      className="form-control mb-2 mx-sm-2 col-md"
                      placeholder={translate('full_name')}
                      value={values.name}
                      onChange={handleChange}
                      dir={dir}
                    />
                    <ErrorMessage> {!!errors.name && errors.name} </ErrorMessage>
                  </FieldWrapper>

                  <button type="submit" style={{width : 250}} className="btn btn-light text-primary">
                    {translate('subscribe')}
                  </button>
                </FormWrapper>
              </form>
            )}
          </Formik>
        </div>
      </Section>
    </Suspense>
  );
}

export default Home;
